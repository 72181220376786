import React from "react"
import { Box, Flex, CloseButton } from "@chakra-ui/core"

const Modal = ({ children, isOpen, onClose, ...props }) => (
  <Flex
    style={{
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      opacity: 1,
      zIndex: isOpen ? "99999" : "-1",
      transition: "all 1s",
    }}
    justifyContent="center"
    alignItems="center"
  >
    <Box
      position="relative"
      zIndex="1"
      bg="white"
      width={{ base: "300px", sm: "350px", md: "400px" }}
      m={5}
      p={8}
      borderRadius="6px"
      transform={isOpen ? `translateY(0%)` : `translateY(-200%)`}
      transition="all 0.5s"
    >
      <CloseButton
        onClick={onClose}
        position="absolute"
        right="3px"
        top="3px"
        _focus={{ outline: 0 }}
      />
      {children}
    </Box>
    <Box
      style={{
        background: "black",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: isOpen ? 0.5 : 0,
        zIndex: "0",
        transition: "all 0.5s",
      }}
    />
  </Flex>
)

export default Modal
