import React, { useState } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import {
  Flex,
  Text,
  Box,
  Heading,
  useTheme,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/core"
import TextInput from "../design-system/text-input"
import EmailInput from "../design-system/email-input"
import NetlifyForm from "react-netlify-form"

import { SectionWrapper, Section } from "../design-system/layout"
import Modal from "../design-system/modal"

import Layout from "../layouts/layout"
import SEO, { parseMetadataFromPrismic } from "../layouts/seo"
import { Global, css } from "@emotion/core"
import { renderText } from "../utils"
import { htmlSerializerPricingFeatures } from "../prismic"
const BOX_COLOURS = ["blue", "green", "red"]
import Button from "../design-system/button"
import { Cfa } from "../slices"

const Page = ({ page, meta, nav, node, siteMetadata }) => {
  const { colors, fontSizes, fonts, space, lineHeights, shadows } = useTheme()
  const [inquiry, setInquiry] = useState(null)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  React.useEffect(() => {
    let timeout = null
    if (success) {
      timeout = setTimeout(() => {
        setSuccess(null)
        setInquiry(null)
      }, 5000)
    }

    return () => timeout && clearTimeout(timeout)
  }, [success])

  return (
    <Layout
      nav={nav}
      siteTitle={page.page_title}
      host={siteMetadata.host}
      seoData={parseMetadataFromPrismic(node, "en-gb")}
    >
      <SectionWrapper mt={{ base: "98px", sm: "112px" }} bg={"gray.100"}>
        <Section
          justifyContent={
            page.pricing_page_title_aligned ? "center" : "flex-start"
          }
        >
          <Heading
            as="h1"
            fontSize={{ base: "3xl", sm: "4xl", md: "5xl" }}
            fontWeight="normal"
            color="gray.800"
            mt={12}
            mb={12}
            mx={3}
            dangerouslySetInnerHTML={{ __html: page.pricing_page_title }}
          />
        </Section>
      </SectionWrapper>
      <SectionWrapper>
        <Section mb={8}>
          <Flex
            width="100%"
            alignItems="center"
            direction="column"
            mx={3}
            css={css`
              p {
                margin: 0.75rem 0;
              }

              button p {
                margin: 0;
              }

              a {
                color: ${colors.blue[500]};
              }

              ul {
                list-style-position: inside;
                text-align: left;

                li {
                  margin-top: 0.25rem;
                  margin-bottom: 0.25rem;
                }
              }

              h2 {
                font-family: ${fonts.body};
                margin: 24px 0;
                font-size: ${fontSizes["2xl"]};
                color: ${colors.gray[700]};
                max-width: 700px;
              }

              h3 {
                font-family: ${fonts.body};
                margin-bottom: ${space[2]};
                font-size: ${fontSizes["xl"]};
                color: ${colors.gray[700]};
                max-width: 700px;
              }

              h4 {
                margin-top: 24px;
                font-size: 20px;
              }
            `}
          >
            {page.body &&
              page.body.map(section => {
                if (section.type === "text") {
                  return (
                    <Flex
                      width="100%"
                      maxWidth="780px"
                      direction="column"
                      mb={5}
                      textAlign={
                        section.primary.pricing_page_section_align_center
                          ? "center"
                          : "left"
                      }
                      alignItems={
                        section.primary.pricing_page_section_align_center
                          ? "center"
                          : "flex-start"
                      }
                    >
                      <RichText render={section.primary.pricing_page_content} />
                    </Flex>
                  )
                }

                if (section.type === "pricing_tables") {
                  return (
                    <Flex
                      width="100%"
                      justifyContent="center"
                      direction={{ base: "column", md: "row" }}
                      alignItems={{ base: "center", md: "stretch" }}
                      wordWrap="unset"
                    >
                      {section.fields &&
                        section.fields.map(
                          (
                            {
                              pricing_table_sub_title,
                              pricing_table_benefits,
                              pricing_table_price,
                              pricing_table_price_currency,
                              pricing_table_price_prefix,
                              pricing_table_title,
                              pricing_table_price_suffix,
                              pricing_table_type,
                              pricing_table_button_label,
                            },
                            index
                          ) => (
                            <Flex
                              bg="white"
                              py={5}
                              my={5}
                              mx={{ base: 0, md: 10 }}
                              //cursor="pointer"
                              border="1px solid"
                              borderTop="3px solid"
                              borderRadius="2px"
                              transition="all 0.3s"
                              position="relative"
                              top={0}
                              direction="column"
                              justifyContent="space-between"
                              width={{ base: "300px", md: "350px" }}
                              boxShadow="md"
                              css={css`
                                border-color: ${colors[BOX_COLOURS[index]][
                                  "200"
                                ]};
                                border-top-color: ${colors[BOX_COLOURS[index]][
                                  "200"
                                ]};

                                @media screen and (min-width: 62em) {
                                  border-color: ${colors.gray["200"]};
                                  border-top-color: ${colors[
                                    BOX_COLOURS[index]
                                  ]["200"]};

                                  &:hover {
                                    border-color: ${colors[BOX_COLOURS[index]][
                                      "300"
                                    ]};
                                    box-shadow: ${shadows.lg};
                                    position: relative;
                                    top: -8px;
                                  }
                                }
                              `}
                            >
                              <Box>
                                <Flex
                                  direction="column"
                                  alignItems="center"
                                  textAlign="center"
                                  fontSize="2xl"
                                  fontFamily="body"
                                  color={colors[BOX_COLOURS[index]]["400"]}
                                  fontWeight="bold"
                                  px={3}
                                >
                                  <Text
                                    width="100%"
                                    dangerouslySetInnerHTML={{
                                      __html: renderText(pricing_table_type),
                                    }}
                                  />
                                  <Box
                                    width="80px"
                                    height="2px"
                                    bg={colors[BOX_COLOURS[index]]["400"]}
                                    zIndex="1"
                                    mb={3}
                                  />
                                </Flex>

                                <Box
                                  px={3}
                                  my={3}
                                  textAlign="center"
                                  fontSize="lg"
                                  fontWeight="semibold"
                                  color="gray.600"
                                  dangerouslySetInnerHTML={{
                                    __html: renderText(pricing_table_title),
                                  }}
                                />
                                <Flex
                                  //bg={colors[BOX_COLOURS[index]]["100"]}
                                  px={3}
                                  py={5}
                                  justifyContent="center"
                                  alignItems="center"
                                  fontStyle="italic"
                                  position="relative"
                                >
                                  <Box
                                    fontSize="xl"
                                    lineHeight="100%"
                                    color={colors[BOX_COLOURS[index]]["400"]}
                                    px={3}
                                    zIndex={1}
                                  >
                                    {pricing_table_price_prefix}
                                  </Box>
                                  <Flex
                                    alignItems="center"
                                    fontStyle="italic"
                                    zIndex={1}
                                    px={3}
                                  >
                                    <Box
                                      fontSize="3xl"
                                      color={colors[BOX_COLOURS[index]]["600"]}
                                      lineHeight="100%"
                                      pr={1}
                                    >
                                      {pricing_table_price_currency}
                                    </Box>
                                    <Box
                                      fontSize="5xl"
                                      lineHeight="44px"
                                      fontWeight="bold"
                                      color={colors[BOX_COLOURS[index]]["800"]}
                                    >
                                      {pricing_table_price}
                                    </Box>
                                    <Box
                                      alignSelf="flex-end"
                                      fontSize="2xl"
                                      lineHeight="100%"
                                      color={colors[BOX_COLOURS[index]]["600"]}
                                    >
                                      {pricing_table_price_suffix}
                                    </Box>
                                  </Flex>
                                  <Box
                                    height="100%"
                                    width="100%"
                                    top={0}
                                    zIndex={0}
                                    position="absolute"
                                    bg={colors[BOX_COLOURS[index]]["50"]}
                                    //bg="gray.100"
                                    style={{
                                      clipPath:
                                        "polygon(100% 0%, 0% 15%, 0% 85%, 100% 100%)",
                                      "-webkit-clip-path":
                                        "polygon(100% 0%, 0% 15%, 0% 85%, 100% 100%)",
                                    }}
                                  />
                                </Flex>
                                <Box px={3} py={5}>
                                  <RichText
                                    render={pricing_table_benefits}
                                    htmlSerializer={
                                      htmlSerializerPricingFeatures
                                    }
                                  />
                                </Box>
                              </Box>
                              <Box>
                                <Box
                                  px={3}
                                  textAlign="center"
                                  fontSize="md"
                                  fontWeight="medium"
                                  color="gray.600"
                                  dangerouslySetInnerHTML={{
                                    __html: renderText(pricing_table_sub_title),
                                  }}
                                />
                                <Flex justifyContent="center" pt={8} pb={5}>
                                  <Button
                                    onClick={() =>
                                      setInquiry({
                                        type: pricing_table_type,
                                        color: BOX_COLOURS[index],
                                      })
                                    }
                                  >
                                    {pricing_table_button_label}
                                  </Button>
                                </Flex>
                              </Box>
                            </Flex>
                          )
                        )}
                    </Flex>
                  )
                }
                if (section.type === "enquire_form") {
                  return (
                    <Modal
                      isOpen={inquiry != null}
                      onClose={() => {
                        setInquiry(null)
                        setError(null)
                        setSuccess(null)
                      }}
                    >
                      <Heading
                        fontSize={{ base: "xl", md: "3xl" }}
                        fontWeight="semibold"
                        fontFamily="body"
                        mb={5}
                      >
                        <Text
                          color={inquiry && colors[inquiry.color]["500"]}
                          dangerouslySetInnerHTML={{
                            __html: inquiry && renderText(inquiry.type),
                          }}
                        />
                        <Box
                          width="80px"
                          height="2px"
                          bg={inquiry && colors[inquiry.color]["500"]}
                          mt={2}
                          zIndex="1"
                          mb={2}
                          position="relative"
                          _before={{
                            content: "''",
                            bg: "white",
                            height: "100%",
                            width: "5px",
                            top: 0,
                            position: "absolute",
                            right: "10px",
                          }}
                          _after={{
                            content: "''",
                            bg: "white",
                            height: "100%",
                            width: "5px",
                            top: 0,
                            position: "absolute",
                            right: "25px",
                          }}
                        />
                      </Heading>
                      <NetlifyForm
                        name="PriceEnquire"
                        onSuccess={() => {
                          setSuccess(
                            section.primary.enquire_form_submit_success_message
                          )
                        }}
                        onError={() =>
                          setError(
                            "Something went wrong, please try again later!"
                          )
                        }
                      >
                        {({ loading, error, success }) => (
                          <>
                            <Input
                              type="hidden"
                              value={inquiry && inquiry.type}
                              name="type"
                            />
                            {section.fields &&
                              section.fields.map(
                                ({
                                  enquire_form_input_placeholder,
                                  enquire_form_input_is_required,
                                }) => (
                                  <FormControl mt={4}>
                                    {enquire_form_input_placeholder
                                      .toLowerCase()
                                      .includes("email") ? (
                                      <EmailInput
                                        width="100%"
                                        required={
                                          enquire_form_input_is_required
                                        }
                                        placeholder={`${enquire_form_input_placeholder}${
                                          enquire_form_input_is_required
                                            ? " *"
                                            : ""
                                        }`}
                                        name={enquire_form_input_placeholder
                                          .toLowerCase()
                                          .replace(/ /g, "_")}
                                      />
                                    ) : (
                                      <TextInput
                                        width="100%"
                                        required={
                                          enquire_form_input_is_required
                                        }
                                        placeholder={`${enquire_form_input_placeholder}${
                                          enquire_form_input_is_required
                                            ? " *"
                                            : ""
                                        }`}
                                        name={enquire_form_input_placeholder
                                          .toLowerCase()
                                          .replace(/ /g, "_")}
                                      />
                                    )}
                                  </FormControl>
                                )
                              )}

                            <FormControl mt={5} textAlign="right">
                              <Button isLoading={loading} type="submit">
                                {
                                  section.primary
                                    .enquire_form_submit_button_label
                                }
                              </Button>
                            </FormControl>
                          </>
                        )}
                      </NetlifyForm>
                      <Text
                        mt={2}
                        opacity={error || success ? 1 : 0}
                        transition="opacity 1s"
                        fontSize="md"
                        fontWeight="medium"
                        textDecoration={success ? "underline" : "unset"}
                        color={error ? "red.400" : "gray.700"}
                        textAlign="center"
                        zIndex={1}
                        textAlign="center"
                        width="100%"
                        position={error || success ? "relative" : "absolute"}
                      >
                        <i>{error || success}</i>
                      </Text>
                    </Modal>
                  )
                }
              })}
          </Flex>
        </Section>
      </SectionWrapper>
      {page.body &&
        page.body.map(section => {
          if (section.type === "cfa") {
            return (
              <Cfa
                id="pricing_page_contact_us"
                cfaType="pricingPage"
                signupSuccess={section.primary.cfa_success_message}
                signupPlaceholder={section.primary.cfa_placeholder}
                signupButton={section.primary.cfa_button_label}
                signupTitle={section.primary.cfa_title}
                campaign="pricing_page"
              />
            )
          }
        })}
    </Layout>
  )
}

export default ({ data }) => {
  const pageContent = data.prismic.pricingPage.edges[0].node
  const meta = data.site.siteMetadata

  const doc = data.prismic.allHomepages.edges.slice(0, 1).pop()
  const nav = doc.node.header_navigation.map(
    ({
      header_navigation__label,
      header_navigation__section,
      header_navigation__type_button,
      header_navigation_link,
    }) => ({
      label: header_navigation__label,
      section: header_navigation__section,
      isButton: header_navigation__type_button,
      link: header_navigation_link,
    })
  )

  return (
    <Page
      page={pageContent}
      meta={meta}
      nav={nav}
      node={doc.node}
      siteMetadata={data.site.siteMetadata}
    />
  )
}

Page.propTypes = {
  page: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}

export const query = graphql`
  query PricingPage {
    prismic {
      pricingPage: allPricing_pages {
        edges {
          node {
            _meta {
              uid
            }
            body {
              ... on PRISMIC_Pricing_pageBodyCfa {
                type
                label
                primary {
                  cfa_title
                  cfa_success_message
                  cfa_placeholder
                  cfa_button_label
                }
              }
              ... on PRISMIC_Pricing_pageBodyPricing_tables {
                type
                label
                fields {
                  pricing_table_sub_title
                  pricing_table_benefits
                  pricing_table_price_currency
                  pricing_table_price
                  pricing_table_price_prefix
                  pricing_table_price_suffix
                  pricing_table_title
                  pricing_table_type
                  pricing_table_button_label
                }
              }
              ... on PRISMIC_Pricing_pageBodyText {
                type
                label
                primary {
                  pricing_page_content
                  pricing_page_section_align_center
                }
              }
              ... on PRISMIC_Pricing_pageBodyEnquire_form {
                type
                label
                fields {
                  enquire_form_input_placeholder
                  enquire_form_input_is_required
                }
                primary {
                  enquire_form_submit_button_label
                  enquire_form_submit_success_message
                }
              }
            }
            pricing_page_title
            prismic_title
            pricing_page_title_aligned
          }
        }
      }
      allHomepages: allWebsite_homepages {
        edges {
          node {
            _meta {
              uid
              id
              type
              lang
            }
            meta_title
            meta_description
            display_name
            canonical_url
            card_description
            card_image
            card_imageSharp {
              childImageSharp {
                fixed(width: 1200, height: 630) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            card_image_square
            card_image_squareSharp {
              childImageSharp {
                fixed(width: 400, height: 400) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            card_title
            header_navigation {
              header_navigation__label
              header_navigation__section
              header_navigation__type_button
              header_navigation_link {
                _linkType
                ... on PRISMIC__FileLink {
                  url
                }
                ... on PRISMIC__Document {
                  _meta {
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
        host
      }
    }
  }
`
